<div>
    <div class="static-basin-image">
        <img class="img-basin" src="assets/img/bacino.png">
    </div>
    <div class="static-sidenav-title">
        <div class="d-flex flex-column justify-content-center" color="primary">
            IL BACINO IDROGRAFICO DEL FIUME PAGLIA
        </div>
    </div>
    <div class="static-sidenav-description">
        La piattaforma EBA4PAGLIA permette di applicare il software EBA4SUB in tutti i sottobacini ( con area drenante maggiore di 3km² e inferiore a 400km² ) del fiume Paglia.
        <br><br>
        Selezionando le “sezioni di calcolo” predefinite l’utente può visualizzare le caratteristiche dei relativi bacini idrografici e procedere alla stima delle onde di progetto.
    </div>
    <div class="col-12 spinner-wrapper" *ngIf=loading>
        <mat-spinner [diameter]="20"></mat-spinner>
        <span class="p-3">Caricamento... </span>
    </div>
</div>