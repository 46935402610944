<app-header></app-header>
<div class="row my-row">
  <div class="col-3 left-panel" *ngIf="!isLeftPanelActive">
    <left-panel-static 
    [loading]="loadingLeftPanelDynamic" ></left-panel-static>
  </div>
  <div class="col-3 left-panel" *ngIf="isLeftPanelActive">
    <left-panel-dynamic 
    [loading]="loadingLeftPanelDynamic" (risultato)="getCalcEbaResults()"></left-panel-dynamic>
  </div>
  <div class="col-9 mappa">
    <app-esri-map></app-esri-map>
  </div>
</div>
<div class="my-row my-SideMenu" *ngIf="isSidemenuActive">
  <div class="col-3 sidemenu">
    <app-static-sidemenu></app-static-sidemenu>
  </div>
  <div class="offset-3 col-9 background-sidemenu" (click)="activeSideMenu()">
  </div>
</div>
<div class="my-left-panel col-12" *ngIf="isResultPanelActive">
  <div class="col-8 background-sidemenu" (click)="getCalcEbaResults()">
  </div>
  <div class="offset-8 col-4 sidemenu">
    <right-panel-result
    [data]="dataOutput"></right-panel-result>
  </div>
</div>