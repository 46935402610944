/*
  Copyright 2020 Esri
  Licensed under the Apache License, Version 2.0 (the "License");
  you may not use this file except in compliance with the License.
  You may obtain a copy of the License at
	http://www.apache.org/licenses/LICENSE-2.0
  Unless required by applicable law or agreed to in writing, software
  distributed under the License is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  See the License for the specific language governing permissions and
  limitations under the License.
*/

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { loadModules } from 'esri-loader';

import { MapStateService } from '../../services/map-state/map-state.service';
import { Subscription } from 'rxjs';

import { ReadFileService } from '../../services/read-file/read-file.service';
import { DashboardComponent } from 'src/app/dashboard/dashboard.component';

import { SendResultImagesService } from 'src/app/services/send-result-images/send-result-images.service';
import { HttpRequestSendPointService } from 'src/app/services/http-request-send-point/http-request-send-point.service';

@Component({
	selector: 'app-esri-map',
	templateUrl: './esri-map.component.html',
	styleUrls: ['./esri-map.component.css']
})

export class EsriMapComponent implements OnInit {

	public mapView: __esri.MapView;
	public countPoints: number = 0;

	public basinLayer: __esri.FeatureLayer;
	public pointsLayer: __esri.FeatureLayer;
	public flowaccLayer: __esri.FeatureLayer;
	
	public demLayer: __esri.GraphicsLayer;
	public pointExtent: __esri.Extent;
	public imageURL : string = "";

	//public mapGallery: __esri.BasemapGallery;
	private sub: Subscription = new Subscription();
	public allPointsString: string;
	public lon: number[] = [];
	public lat: number[] = [];
	public nearestPoint: number[] = [];

	public lon_selected_point: number = 0;
	public lat_selected_point: number = 0;

	public loading : boolean = false;
	public isFirstDemSelected : boolean = false;
	public isShowDem : boolean = false;

	constructor(private msService: MapStateService, 
		private http: ReadFileService, 
		private objDashboard: DashboardComponent, 
		private objSenResultIMage: SendResultImagesService, 
		private objRequestSendPoint: HttpRequestSendPointService) { }

	public ngOnInit() {
		return loadModules([
			'esri/config',
			'esri/Map',
			'esri/views/MapView',
			'esri/layers/FeatureLayer',
			'esri/layers/GraphicsLayer',
			'esri/Graphic',
			'esri/widgets/BasemapToggle',
			'esri/widgets/BasemapGallery',
			'esri/layers/TileLayer', 
			'esri/layers/WebTileLayer',
			'esri/geometry/Point',
			'esri/geometry/Extent'

		]).then(([esriConfig, Map, MapView, FeatureLayer, GraphicsLayer, Graphic, BasemapToggle, BasemapGallery, TileLayer, WebTileLayer, Point, Extent]) => {
			esriConfig.apiKey = "AAPK2022a2f1eafc49e8b46d9cfcbf1025f1h8GfF99ki0JIr_DJfxj-BgnfEO7a22E2fJAFKBcXJGyKxKxmGPbBDG_9c-Ny1iFV";
			var map = new Map({
				basemap: 'topo-vector'
				// basemap è la base di tutta la mappa
				// ry one of these: "streets", "satellite", "hybrid", "terrain", "topo", "gray", "dark-gray", "oceans", 
				//"national-geographic", "osm", "dark-gray-vector", "gray-vector", "streets-vector", "topo-vector", 
				//"streets-night-vector", "streets-relief-vector", "streets-navigation-vector"
			});
			this.mapView = new MapView({
				container: "mapViewNode",
				center: [11.922780, 42.816067],
				zoom: 10.5,
				map: map,
				constraints: {minZoom:5, maxZoom:14}
			});

			var toggle = new BasemapToggle({
				view: this.mapView, // view that provides access to the map's 'topo' basemap
				nextBasemap: "hybrid", // allows for toggling to the 'hybrid' basemap
			});
			this.mapView.ui.add(toggle, "top-right");

			/*
			this.mapGallery = new BasemapGallery({
				showArcGISBasemaps: false,
				view: this.mapView,
			})
			this.mapView.ui.add(this.mapGallery, "bottom-left");

			*/
			this.basinLayer = new FeatureLayer(
				{ url: "https://services6.arcgis.com/dPuhWBX45Az4pEyf/arcgis/rest/services/bacinodelpaglia/FeatureServer/0" });
			map.add(this.basinLayer);
			this.flowaccLayer = new FeatureLayer(
				{ url: "https://services6.arcgis.com/dPuhWBX45Az4pEyf/arcgis/rest/services/reticoloidrografico160622/FeatureServer/0" });
			map.add(this.flowaccLayer);
			this.pointsLayer = new FeatureLayer(
				{ url: "https://services6.arcgis.com/dPuhWBX45Az4pEyf/arcgis/rest/services/book220622/FeatureServer/0" });
			map.add(this.pointsLayer);
			this.mapView.map.layers.getItemAt(2).visible = false;

			// //--- START CODE TO ELABORATE THE PONTS FILE ON MAP ---	
			this.http.getfile().subscribe(Response => {
				this.allPointsString = Response;
				this.elaborationFile();
				//this.view_points();
			})
			// //--- END CODE TO ELABORATE THE PONTS FILE ON MAP ---

			this.mapView.on('click', async (event: __esri.MapViewClickEvent) => {
				
				if (this.loading == false)
				{
					this.loading = true;
					this.objDashboard.setStatusLoadingLeftPanel();
					this.mapView.graphics.pop();
					const pointGraphic = new Graphic({
						attributes: {
							time: new Date().getTime()
						},
						geometry: {
							type: 'point',
							longitude: event.mapPoint.longitude,
							latitude: event.mapPoint.latitude,
							spatialReference: event.mapPoint.spatialReference
						},
						symbol: {

							type: 'simple-marker',
							size: "8px",  // pixels
							color: [0, 0, 255],
							outline:
							{
								color: [255, 255, 255],
								width: 0
							}
						},
					});

					// Get the coordinate selected point
					this.lon_selected_point = event.mapPoint.longitude;
					this.lat_selected_point = event.mapPoint.latitude;

					this.nearestPoint = this.getNeearestPoint(this.lon_selected_point, this.lat_selected_point);

					this.viewNearestPoint(this.nearestPoint[0], this.nearestPoint[1]);
					console.log(this.nearestPoint[0], this.nearestPoint[1]);
					 
					let imagesResult = await this.objRequestSendPoint.elaborate(this.nearestPoint[1], this.nearestPoint[0]).toPromise();
					let image_xmin = imagesResult.corner_topleft.split(';')[0]
					let image_ymin = imagesResult.corner_topleft.split(';')[1]
					let image_xmax = imagesResult.corner_bottomright.split(';')[0]
					let image_ymax = imagesResult.corner_bottomright.split(';')[1]
					//----- MapDem -----
					if (this.isFirstDemSelected == true) {
						this.mapView.map.remove(this.demLayer);
					}
 					var base64image =imagesResult.col_img
					this.imageURL = "data:image/png;base64,"+base64image;
					
					this.pointExtent = new Extent({
						 xmin: image_xmin, 
						 ymin: image_ymin, 
						 xmax: image_xmax, 
						 ymax: image_ymax
						});
					var point = new Point ({
						x: this.pointExtent.xmin +(this.pointExtent.xmax - this.pointExtent.xmin)/2, 
						y: this.pointExtent.ymin +(this.pointExtent.ymax - this.pointExtent.ymin)/2
					});

					var symbolMarker = { 
						type: "picture-marker",
						contentType: 'image/png',
						url: this.imageURL
					};
					var graphicPoint = new Graphic({
						geometry: point,
						symbol: symbolMarker
					});
					this.demLayer = new GraphicsLayer({
						graphics: [graphicPoint],
						opacity: 0.5
					});

					this.mapView.watch("animation", (response) => {
						if(response && response.state === "running"){
							this.demLayer.visible = false;
						}
					  });
					this.mapView.watch("stationary", (newValue, oldValue) => {
						if (newValue == true) { 
							this.adjustMarker(this.imageURL);
						}
					});
					
					this.adjustMarker(this.imageURL);
					map.add(this.demLayer);
					//----- dne -----
					this.objSenResultIMage.setCurrentValue(imagesResult);
					this.msService.addPoint(pointGraphic);  //per scrivere nella finestra 
					this.objDashboard.activeLeftPanel();
					this.loading=false;
					this.objDashboard.setStatusLoadingLeftPanel();
					this.isFirstDemSelected = true;
				}
			});
		})
			.catch(err => {
				console.error(err);
			});
	}

	public adjustMarker(base64image: string) {

		return loadModules([
			'esri/geometry/Point'
		]).then(([Point]) => {

			var pointmax = new Point ({
				x: this.pointExtent.xmax,
				y: this.pointExtent.ymax
			});
			var pointin = new Point ({
				x: this.pointExtent.xmin,
				y: this.pointExtent.ymin
			});
			var topRightScreenPt = this.mapView.toScreen(pointmax);
			var bottomLeftScreenPt = this.mapView.toScreen(pointin);
			const newWidth = Math.abs(topRightScreenPt.x - bottomLeftScreenPt.x);
			const newHeight = Math.abs(bottomLeftScreenPt.y - topRightScreenPt.y);
			var symbolMarkerDynamic = {
				contentType: 'image/png',
				url: this.imageURL,
				width: newWidth > 0 ? newWidth + "px" : 1,
				height: newHeight > 0 ? newHeight + "px" : 1
			};
			this.demLayer.graphics.getItemAt(0).symbol.set(symbolMarkerDynamic);
			this.demLayer.visible = true;
		});
	}

	public elaborationFile() {

		let righe: string[]=[]
		if(this.allPointsString.includes("\r\n")){

			righe = this.allPointsString.split("\r\n");
		}else{

			righe = this.allPointsString.split("\n");
		}
		let riga: string[];
		this.countPoints = righe.length;

		for (let index = 1; index < this.countPoints; index++) {
			riga = righe[index].split(";");
			this.lon[index - 1] = <number>(<unknown>riga[1]);
			this.lat[index - 1] = <number>(<unknown>riga[2]);
		}
	}

	public getNeearestPoint(lon: number, lat: number): number[] {
		let d_min: number;
		let d: number;
		let nearestPoint: number[] = [];

		for (let index = 1; index < this.countPoints; index++) {
			d = Math.sqrt(Math.pow(lon - this.lon[index - 1], 2) + Math.pow(lat - this.lat[index - 1], 2))
			if ((d < d_min) || (index == 1)) {
				d_min = d;
				nearestPoint[0] = this.lon[index - 1];
				nearestPoint[1] = this.lat[index - 1];
			}
		}
		return nearestPoint;
	}

	public viewNearestPoint(lon: number, lat: number) {

		return loadModules([
			'esri/Graphic'
		]).then(([Graphic]) => {
			const point_onriver = new Graphic({
				geometry: {
					type: 'point',
					longitude: lon,
					latitude: lat,
					spatialReference: this.mapView.spatialReference
				},

				symbol: {
					type: 'simple-marker',
					size: "12px",  // pixels
					color: [220, 20, 60],
					outline: {
						color: [255, 255, 255],
						width: 0
					}
				},
			});
			this.mapView.graphics.add(point_onriver);
		});
	}
}


