<header class="sub-nav" role="banner">
<style>
.grid-container {
   background-color: rgb(45, 69, 83);
 }
</style>
  <div class="grid-container" >
    <div class="column-10">
      <h1 class="sub-nav-title text-black">MAPPA</h1>
      <nav class="sub-nav-list" role="navigation" aria-labelledby="subnav">
        <a class="sub-nav-link" routerLink="/map" routerLinkActive="active">Map</a>
        <a class="sub-nav-link" routerLink="/dashboard" routerLinkActive="active">Tab</a>
      </nav>
    </div>
  </div>
</header>
