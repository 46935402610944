<div class="row">
    <div class="mt-2 sidemenu-list"><a href="#" class="sidemenu-list-link">Link -> #1</a></div>
    <div class="mt-4 sidemenu-list"><a href="#" class="sidemenu-list-link">Link -> #2</a></div>
    <div class="mt-4 sidemenu-list"><a href="#" class="sidemenu-list-link">Link -> #3</a></div>
    <div class="mt-4 sidemenu-list"><a href="#" class="sidemenu-list-link">Link -> #4</a></div>
    <div class="mt-4 sidemenu-list"><a href="#" class="sidemenu-list-link">Link -> #5</a></div>
    <div class="mt-4 sidemenu-list"><a href="#" class="sidemenu-list-link">Link -> #6</a></div>
    <div class="mt-4 sidemenu-list"><a href="#" class="sidemenu-list-link">Link -> #7</a></div>
    <div class="mt-4 sidemenu-list"><a href="#" class="sidemenu-list-link">Link -> #8</a></div>
</div>
