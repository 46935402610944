import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-static-sidemenu',
  templateUrl: './static-sidemenu.component.html',
  styleUrls: ['./static-sidemenu.component.scss']
})
export class StaticSidemenuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
