<div class="col-12 header" >
  <div class="col-10">
    <div style="font-size: 25px; margin-left: 10px;">Piattaforma per la stima delle onde di piena di progetto (per uso didattico)</div>
  </div>
  <div class="col-2 d-flex flex-row justify-content-end" (click)="comingsoon()">
    Accedi / Registrati<mat-icon style="margin-right:20px; margin-left:10px;cursor: pointer;">account_circle</mat-icon>
  </div>
</div>
<div class="col-12 sub-header">
  <div class="d-flex flex-column align-items-start" >
    <button class="d-flex flex-column align-items-start" (click)="openSideMenu()" style="border:none; margin-left: 10px; background-color:transparent">
      <mat-icon style="transform: scale(1);" color="primary">menu</mat-icon>
    </button>
  </div>
  <div class="col-1 d-flex flex-column align-items-center">
    <img class="logo-uni" src="assets/img/Logo-Tuscia.png">
  </div>
  <div class="col-1 d-flex flex-row align-items-center justify-content-center">
    <img class="logo-eba4sub" src="assets/img/Logo-EBA4SUB.png">
  </div>
  <div class="col-1 d-flex flex-row align-items-center justify-content-start">
    <img class="logo-cosmo4sub" src="assets/img/Logo-COSMO4SUB.png">
  </div>
  <div class="col-2">
  </div>
  <div class="col-2 d-flex flex-row justify-content-center">
    <mat-slide-toggle color="primary" [checked]="statePointsLayer" (change)="setLayer(2)">Sezioni di calcolo</mat-slide-toggle>
  </div>
  <div class="col-2 d-flex flex-row justify-content-center">
    <mat-slide-toggle color="primary" [checked]="stateFlowaccLayer" (change)="setLayer(1)">Reticolo idrografico</mat-slide-toggle>
  </div>
  <div class="col-2 d-flex flex-column justify-content-center align-items-end">
  </div>
  <div class="d-flex flex-row align-items-end" style="font-size: 10px;" >
    v 0.0.4
  </div>
</div>
