import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { SendResultImagesService } from 'src/app/services/send-result-images/send-result-images.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatTableDataSource } from '@angular/material/table';



export interface structureAN{
  tr: string;
  a: string;
  n: string;
}

@Component({
  selector: 'left-panel-dynamic',
  templateUrl: './left-panel-dynamic.component.html',
  styleUrls: ['./left-panel-dynamic.component.scss']
})
export class LeftPanelDynamicComponent implements OnInit {

  public subscription : Subscription;
  public actualImageResult : SendResultImagesService;
  public basinImageBase64 : any;
  public demImageBase64 : any;
  public BasinArea : string = "";
  public Tc : string = "";
  public Cn : string = "";
  public TimeReturn : string = "";
  public line : string[] = [];
  public an_array : string[] = [];
  public an_array_element : structureAN[] = [{tr:'tr' , a:"a", n:"n"}];
  public dataSource = new MatTableDataSource(this.an_array_element);
  public displayedColumns : string[] = ["tr","a","n"];
  
  @Input("loading") public loading : boolean;

  @Output("risultato") public risultato : EventEmitter<void> = new EventEmitter<void>();

  constructor( 
    private objImageService: SendResultImagesService, 
    private objDomSanitizer: DomSanitizer) { }

  ngOnInit(): void {

    let actualImageResult = this.objImageService.getCurrentValue();
    this.basinImageBase64 = this.objDomSanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + actualImageResult.img);
    this.demImageBase64 = this.objDomSanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + actualImageResult.col_img);
    this.BasinArea = actualImageResult.basinarea.replace(",",".");
    console.log(actualImageResult.tc);
    this.Tc = Number(actualImageResult.tc.replace(",",".")).toFixed(1);
    this.Cn = actualImageResult.cn.replace(",",".");
    this.an_array = actualImageResult.an_string.split(";").filter(element => element);
    for (let j = 0; j < this.an_array.length; j++) {
      this.line = this.an_array[j].split("-");
      this.an_array_element[j] = {tr: this.line[0], a: (Number(this.line[1])).toFixed(1), n: (Number(this.line[2])).toFixed(2)};
    }
    this.dataSource = new MatTableDataSource(this.an_array_element);

    this.subscription = this.objImageService.objCurrentResultImages.subscribe(
      actualImageResult =>{
        this.basinImageBase64 = this.objDomSanitizer.bypassSecurityTrustResourceUrl("data:image/png;base64, " + actualImageResult.img);
        this.demImageBase64 = this.objDomSanitizer.bypassSecurityTrustResourceUrl("data:image/png;base64, " + actualImageResult.col_img);
        this.BasinArea = actualImageResult.basinarea.replace(",",".");
        this.Tc = Number(actualImageResult.tc.replace(",",".")).toFixed(1);
        this.Cn = actualImageResult.cn.replace(",",".");
        this.an_array = actualImageResult.an_string.split(";").filter(element => element);
        for (let j = 0; j < this.an_array.length; j++) {
          this.line = this.an_array[j].split("-");
          this.an_array_element[j] = {tr: this.line[0], a: (Number(this.line[1])).toFixed(1), n: (Number(this.line[2])).toFixed(2)};
        }
        this.dataSource = new MatTableDataSource(this.an_array_element);
      }
    )
  }
  
  public getEbaResults() {
    this.risultato.emit();
  }

}
