<div>
    <div class="row col-12 container-images">
        <div class="col-12 dynamic-basin-dem-image">
            <img class="img-basin" [src]="basinImageBase64">
        </div>
        <!-- <div class="col-4 dynamic-dem-image">
            <img class="img-dem" [src]="demImageBase64">
        </div> -->
    </div>
    <div class="dynamic-sidenav-description">
        <br>Area del bacino selezionato [km²]: {{BasinArea}} 
        <br>Tempo di corrivazione [h]: {{Tc}} 
        <br>Valore del Curve Number [-]: {{Cn}}
    </div>
    <div class="dynamic-sidenav-table" >
        <table mat-table class="taban" [dataSource]="dataSource" >
            <ng-container matColumnDef="tr">
                <th mat-header-cell *matHeaderCellDef class="title-row">Tr [y]</th>
                <td mat-cell *matCellDef="let element" class="item-text"> {{element.tr}} </td>
            </ng-container>
            
            <ng-container matColumnDef="a">
                <th mat-header-cell *matHeaderCellDef class="title-row">a [mm/h] </th>
                <td mat-cell *matCellDef="let element" class="item-text"> {{element.a}} </td>
            </ng-container>
    
            <ng-container matColumnDef="n">
                <th mat-header-cell *matHeaderCellDef class="title-row">n [-] </th>
                <td mat-cell *matCellDef="let element" class="item-text"> {{element.n}} </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <div class="col-12 dynamic-sidenav-button" *ngIf=loading>
        <button mat-button class="eba-button-disabled col-8" color="accent" >Calcola le onde di progetto</button>
    </div>
    <div class="col-12 dynamic-sidenav-button" *ngIf=!loading>
        <button mat-button class="eba-button col-8" color="accent" (click)="getEbaResults()">Calcola le onde di progetto</button>
    </div>
    <div class="col-12 spinner-wrapper" *ngIf=loading>
        <mat-spinner [diameter]="20"></mat-spinner>
        <span class="p-3">Caricamento... </span>
    </div>
</div>