import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { RequestPointResponse } from 'src/app/models/responses/eba-image-response';


@Injectable({
  providedIn: 'root'
})
export class SendResultImagesService {

  private objResultImages = new Subject<RequestPointResponse>();
  private objInitialResultImages : RequestPointResponse;
  public objCurrentResultImages = this.objResultImages.asObservable();

  constructor() { }

  setCurrentValue(images:RequestPointResponse){
    this.objResultImages.next(images);
    this.objInitialResultImages = images;
  }
  getCurrentValue(){
    return this.objInitialResultImages;
  }
}
