import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestPointResponse } from 'src/app/models/responses/eba-image-response';
import { AllDataOutputResponse } from 'src/app/models/responses/eba-result-response';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpRequestSendPointService {

  constructor(private http: HttpClient) { }


  /**
   * Metodo per eseguire la richiesta HTTP per la conversione da file ASC ad immagine in formato Base64
   * @param x coordinate latitudine punto piu vicino
   * @param y coordinate longitudine punto piu vicino
   * @param user_token coordinate longitudine punto piu vicino
   * @returns Risposta HTTP che contiene Base64 e dimensioni dell'immagine in pixels.
   */
   public elaborate(x:number, y:number): Observable<RequestPointResponse> {

    let url: string = environment.baseEndpoint + "api/images";

    const formData = new FormData();
    formData.append('y', y.toString());
    formData.append('x', x.toString());
    formData.append('user_token', localStorage.getItem('usr_tkn'));

    return this.http.post<RequestPointResponse>(url, formData);
  }

    /**
   * Metodo per eseguire la richiesta HTTP per la conversione da file ASC ad immagine in formato Base64
   * @param user_token coordinate longitudine punto piu vicino
   * @returns Risposta HTTP che contiene Base64 e dimensioni dell'immagine in pixels.
   */
     public outputeba4sub(): Observable<AllDataOutputResponse> {

      let url: string = environment.baseEndpoint + "api/waves";
  
      const formData = new FormData();
      formData.append('user_token', localStorage.getItem('usr_tkn'));
  
      return this.http.post<AllDataOutputResponse>(url, formData);
    }
}
