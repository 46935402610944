import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Injectable } from '@angular/core';
import { AllDataOutputResponse } from 'src/app/models/responses/eba-result-response';
import { EChartsOption } from 'echarts';
import { FilesResponse } from 'src/app/models/responses/eba-files-response';
import { HttpGetFilesService } from 'src/app/services/get-files/http-get-files.service';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { HttpRequestSendPointService } from 'src/app/services/http-request-send-point/http-request-send-point.service';


// export interface structureData{
//   description: string;
//   value: string;
// }
export interface structureData{
  item1: string;
  item2: string;
  item3: string;
  item4: string;
}
@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'right-panel-result',
  templateUrl: './right-panel-result.component.html',
  styleUrls: ['./right-panel-result.component.scss']
})
export class RightPanelResultComponent implements OnInit {

  public graphOptions: EChartsOption;
  //public dataOutputTable : structureData[] = [{description:'Description' , value:'Value'}];
  public dataOutputTable : structureData[] = [];
  
  public files : FilesResponse;

  public displayedColumns: string[];
  public isAllData: boolean = false;
  public isLoadingDownload: boolean = false;

  @Input("data") public AllData : AllDataOutputResponse;


  constructor(private objFileService : HttpGetFilesService, private objRequestSendPoint: HttpRequestSendPointService) { }

  ngOnInit(): void {
    this.getOutputData();
  }

  public async outputDownload(){
    this.isLoadingDownload = true;
    this.files = await this.objFileService.getfiles().toPromise();
    let info_txt = this.elaboratefilestext();

    // INSERT OF ALL FIELDS IN A FILE
    const jszip = new JSZip();
    jszip.file("dem.asc", this.files.dem.toString());
    jszip.file("flowaccumulation.asc", this.files.acc.toString());
    jszip.file("flowdirection.asc", this.files.fdir.toString());
    jszip.file("corine_landcover.asc", this.files.corine.toString());
    jszip.file("InitialSettingsInfo.txt", info_txt[0]);
    jszip.file("LandSettingsInfo.txt", info_txt[1]);
    jszip.file("GraphValue.txt", info_txt[2]);

    jszip.generateAsync({ type: 'blob' }).then((content) => {
      let data_oggi : string = new Date().toLocaleDateString("it-IT");
      data_oggi = data_oggi.replace('/','_');
      let nameZip = data_oggi + "-Eba4Sub_Data.zip";
      saveAs(content, nameZip);
      this.isLoadingDownload = false;
    });
  }
  public openInEba4Sub(){
    alert("COMING SOON...");
  }

  public elaboratefilestext(): [string,string,string]{
    let step1_string : string;
    let step3_string : string;
    let graph_string : string;
    step1_string="--- Initial Settings --- \r\n";
    step1_string=step1_string+"Hyetograph time step [min]: " + this.AllData.infoData["hyetographStep"] + "\r\n";
    step1_string=step1_string+"WFIUH time step [min]: " + this.AllData.infoData["iuhStep"] + "\r\n";
    step1_string=step1_string+"Drainage network threshold area [m²]: " + this.AllData.infoData["dnta"] + "\r\n";
    step1_string=step1_string+"Min velocity in hillslope cells [m/s]: " + this.AllData.infoData["slopeVelMin"] + "\r\n";
    step1_string=step1_string+"Max velocity in hillslope cells [m/s]: " + this.AllData.infoData["slopeVelMax"] + "\r\n";
    step1_string=step1_string+"Initial soil conductivity [mm/h]: " + this.AllData.infoData["KGa0"] + "\r\n";
    step1_string=step1_string+"Initial soil suction head [mm]: " + this.AllData.infoData["SGa0"] + "\r\n";
    step1_string=step1_string+"Initial soil moisture [-]: " + this.AllData.infoData["SmdGa0"] + "\r\n";
    step1_string=step1_string+"Saturated soil moisture [-]: " + this.AllData.infoData["SmsGa0"] + "\r\n";
    step3_string="--- Land Settings --- \r\n";
    step3_string=step3_string+"Basin Area [km²]: " + this.AllData.infoData["basinArea"] + "\r\n";
    step3_string=step3_string+"AMC: " + this.AllData.infoData["amc"] + "\r\n";
    step3_string=step3_string+"HSG: " + this.AllData.infoData["nsg"] + "\r\n";
    step3_string=step3_string+"CN: " + this.AllData.infoData["cn"] + "\r\n";
    step3_string=step3_string+"Lambda: " + this.AllData.infoData["lambda"] + "\r\n";
    graph_string="";

    for (let k = 0; k < this.AllData.rainGraphDto[3].labels.length; k++) {
      if (this.AllData.rainGraphDto[3].labels[k].includes("volume"))
        graph_string=graph_string+ this.AllData.rainGraphDto[3].labels[k].replace("m^3","m³") + (this.AllData.rainGraphDto[3].data[k]/1000000) + "\r\n";
      else
      graph_string=graph_string+ this.AllData.rainGraphDto[3].labels[k].replace("m^3","m³") + (this.AllData.rainGraphDto[3].data[k]) + "\r\n";
    }
    return [step1_string, step3_string, graph_string];
  }

  public async getOutputData(){
    this.AllData = await this.objRequestSendPoint.outputeba4sub().toPromise();
    if(!this.AllData)
      return;
    // Set the labels and values to graph
    this.manageGraphData(this.AllData.rainGraphDto[2].labels,this.AllData.rainGraphDto[2].data);
    // Set the labels and values to show in the table
    this.displayedColumns = ['event','peakflow','totalvolume','duration'];
    this.elaborationDataToTable(this.AllData.rainGraphDto[3].labels,this.AllData.rainGraphDto[3].data);
  }

  public elaborationDataToTable(labels : string[], values : number[])
  {
    let valueDataToString : string = "";
   
    for (let i = 0; i < labels.length; i++) {   
      this.dataOutputTable.push({item1: values[i].toString(), item2: values[i+3].toString(), item3: (values[i+4]/1000000).toFixed(3), item4: values[i+5].toString()});
      i = i+5;
    }
    
  }

  public manageGraphData(labels : string[], values : number[]) {

    this.graphOptions = {
      xAxis: {
        data: labels,
        type: 'category',
        boundaryGap: false,
        axisLabel:{
          show:true,
          rotate: 90,
          fontSize: 6
        },
        splitLine:{
          show: true
        },
        name: 'Tempo [hh-mm]',
        nameLocation: 'middle',
        nameGap: 30
      },
      tooltip : {
        trigger: 'item'
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100
        }
      ],
      yAxis: {
        type: 'value',
        name: 'Portata [m³/s]',
        nameLocation: 'middle',
        nameGap: 30
      },
      series: [{
        data: values,
        type: 'line',
        animation: false
      }]
    };
  }
}
