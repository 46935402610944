import { Component, Input, OnInit } from '@angular/core';
import { DashboardComponent } from 'src/app/dashboard/dashboard.component';
@Component({
  selector: 'left-panel-static',
  templateUrl: './left-panel-static.component.html',
  styleUrls: ['./left-panel-static.component.scss']
})
export class LeftPanelStaticComponent implements OnInit {


  @Input("loading") public loading : boolean;

  constructor(private objDashboard: DashboardComponent) { }

  ngOnInit(){

  }

}
