import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { EsriMapComponent } from './components/esri-map/esri-map.component';
import { HeaderComponent } from './components/header/header.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { DashboardComponent } from './dashboard/dashboard.component';

import { NgxEchartsModule } from 'ngx-echarts';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { HttpClientModule } from  '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatTableModule} from '@angular/material/table';

import { StaticSidemenuComponent } from './components/static-sidemenu/static-sidemenu.component';
import { RightPanelResultComponent } from './components/right-panel-result/right-panel-result.component';
import { LeftPanelStaticComponent } from './components/left-panel-static/left-panel-static.component';
import { LeftPanelDynamicComponent } from './components/left-panel-dynamic/left-panel-dynamic.component';

const routes = [
  { path: 'dashboard', component: DashboardComponent },
  { path: '', redirectTo: "dashboard", pathMatch: "full" }
];

@NgModule({
  declarations: [
    AppComponent,
    EsriMapComponent,
    HeaderComponent,
    NavigationComponent,
    DashboardComponent,
    StaticSidemenuComponent,
    RightPanelResultComponent,
    LeftPanelStaticComponent,
    LeftPanelDynamicComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),
    ScrollingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatSlideToggleModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    MatProgressSpinnerModule
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],

  bootstrap: [AppComponent]
})
export class AppModule { }
