/*
  Copyright 2020 Esri
  Licensed under the Apache License, Version 2.0 (the "License");
  you may not use this file except in compliance with the License.
  You may obtain a copy of the License at
    http://www.apache.org/licenses/LICENSE-2.0
  Unless required by applicable law or agreed to in writing, software
  distributed under the License is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  See the License for the specific language governing permissions and
  limitations under the License.
*/

import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { EsriMapComponent } from '../components/esri-map/esri-map.component';
import { RightPanelResultComponent } from '../components/right-panel-result/right-panel-result.component';
import { AllDataOutputResponse } from '../models/responses/eba-result-response';
import { HttpRequestSendPointService } from '../services/http-request-send-point/http-request-send-point.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements AfterViewInit {
 
  @ViewChild (EsriMapComponent) public esriMap;

  public mapDashboardObject : EsriMapComponent;

  public labels : string[];
  public values : string[];

  public isSidemenuActive: boolean = false;
  public isResultPanelActive: boolean = false;
  public isLeftPanelActive: boolean = false;
  public loadingLeftPanelDynamic : boolean = false; 

  public dataOutput : AllDataOutputResponse;
  
  currentItem=" -- " //definizione variabile per registrare una stringa 
  message="----"
  getValue(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }

  constructor(private objRightPanel: RightPanelResultComponent, 
		private objRequestSendPoint: HttpRequestSendPointService) { 
   
  }

  ngAfterViewInit() {
    //check local storage else create new token?
    if (!localStorage.getItem('usr_tkn'))
    {
      let new_token = Math.floor(Date.now()/1000);
      localStorage.setItem('usr_tkn', new_token.toString())
    }
    //setto la variabile locale uguale a quella del componente figlio
    this.mapDashboardObject = this.esriMap;
  }

  public activeSideMenu()
  {
    if (this.isSidemenuActive == true) {
      this.isSidemenuActive = false;
    }
    else{
      this.isSidemenuActive = true;
    }
  }

  public async getCalcEbaResults()
  {
    if (this.isResultPanelActive == true) {
      this.isResultPanelActive = false;
    }
    else{
      this.isResultPanelActive = true;
    }
  }

  public activeLeftPanel()
  {
    this.isLeftPanelActive = true;
  }

  public setStatusLoadingLeftPanel()
  {
    this.loadingLeftPanelDynamic = this.mapDashboardObject.loading; 
  }
}
