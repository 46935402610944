<div class="row col-12 right-panel-main">
    <div class="row right-panel-title">
      <h4>ONDE DI PIENA DI PROGETTO</h4>
      <h6>Calcolate con il software EBA4SUB per 4 tempi di ritorno</h6>
    </div>
    <div class="col-12 all-data" *ngIf="graphOptions; else loadingBlock">
        <div echarts id="GraphDiv2" [options]="graphOptions" class="chart"></div>
        <div class="tableScroll">
          <table mat-table [dataSource]="dataOutputTable"  class="taban">
            <ng-container matColumnDef="event">
              <th mat-header-cell *matHeaderCellDef class="title-row">Tempo di Ritorno <br>[y]</th>
              <td mat-cell *matCellDef="let element" class="item-text"> {{element.item1}} </td>
            </ng-container>
            <ng-container matColumnDef="peakflow">
              <th mat-header-cell *matHeaderCellDef class="title-row"> Portata <br> [m³/s] </th>
              <td mat-cell *matCellDef="let element" class="item-text"> {{element.item2}} </td>
            </ng-container>
            <ng-container matColumnDef="totalvolume">
              <th mat-header-cell *matHeaderCellDef class="title-row"> Volume <br>[10<sup>6</sup>x m³] </th>
              <td mat-cell *matCellDef="let element" class="item-text"> {{element.item3}} </td>
            </ng-container>
            <ng-container matColumnDef="duration">
              <th mat-header-cell *matHeaderCellDef class="title-row"> Durata <br>[h] </th>
              <td mat-cell *matCellDef="let element" class="item-text"> {{element.item4}} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
      </div>
      <div class="col-12 right-panel-buttons-app-div" >
        <button mat-button *ngIf= graphOptions&&!isLoadingDownload class="right-panel-button-disabled col-4" (click)="openInEba4Sub()">Apri in COSMO4SUB</button>
        <button mat-button *ngIf=isLoadingDownload class="right-panel-button-disabled col-4" >Apri in COSMO4SUB</button>
        <div class="col-2"></div>
        <button mat-button *ngIf= graphOptions&&!isLoadingDownload class="right-panel-button-disabled col-4" (click)="openInEba4Sub()">Apri in EBA4SUB</button>
        <button mat-button *ngIf=isLoadingDownload class="right-panel-button-disabled col-4" >Apri in EBA4SUB</button>
      </div>
    </div>
    <ng-template class="row" #loadingBlock>
      <div class="mt-4 spinner-wrapper">
        <mat-spinner [diameter]="20"></mat-spinner>
        <span class="p-3">Caricamento Dati... </span>
      </div>
    </ng-template> 
    <div class="col-12 spinner-wrapper-download" *ngIf=isLoadingDownload>
      <mat-spinner [diameter]="13"></mat-spinner>
      <span class="p-3">Preparo il Download... </span>
    </div>
    <div class="col-12 spinner-wrapper-download" *ngIf=!isLoadingDownload>
      <span class="p-3">-</span>
    </div>
    <div class="col-12 right-panel-button-div" >
      <button mat-button *ngIf= graphOptions&&!isLoadingDownload class="right-panel-button col-10" (click)="outputDownload()">Salva i Risultati</button>
      <button mat-button *ngIf=isLoadingDownload class="right-panel-button-disabled col-10" >Salva i Risultati</button>
    </div>
</div>