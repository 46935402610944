
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FilesResponse } from 'src/app/models/responses/eba-files-response';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpGetFilesService {

  constructor(private http: HttpClient) { }

  /**
   * Metodo per ricevere i 4 file
   * @param user_token coordinate longitudine punto piu vicino
   * @returns Risposta HTTP che contiene i 4 files
   */
   public getfiles(): Observable<FilesResponse> {

    let url: string = environment.baseEndpoint + "api/getfiles";

    const formData = new FormData();
    formData.append('user_token', localStorage.getItem('usr_tkn'));

    return this.http.post<FilesResponse>(url, formData);
  }

}
